<template>
  <section class="section">
    <h1 class="title">Datenschutzerklärung</h1>
    <div class="block">
      <b>Einführung</b><br>
      Wir bei kawo nehmen den Schutz Ihrer persönlichen Daten ernst. Diese Datenschutzerklärung informiert Sie darüber, wie wir mit Informationen umgehen, die während Ihres Besuchs auf unserer Website [obc-kawo.de] gesammelt werden.
    </div>

    <div class="block">
      <b>Datenerfassung und -nutzung</b><br>
      Kein Tracking: Wir verfolgen eine strikte "Kein Tracking"-Politik. Das bedeutet, dass wir während Ihres Besuchs auf unserer Website keine persönlichen Daten erfassen. Es werden keine Cookies verwendet, um Ihr Verhalten zu verfolgen oder zu analysieren.

      Systemprotokolle: Für den Betrieb und die Wartung unserer Website können allgemeine technische Daten (wie Ihr Browser-Typ, das Datum und die Uhrzeit Ihres Zugriffs) in Serverprotokollen gespeichert werden. Diese Daten sind nicht personenbezogen und dienen ausschließlich technischen Zwecken.    </div>

    <div class="block">
      <b>Datensicherheit</b><br>
      Obwohl wir keine persönlichen Daten erfassen, setzen wir modernste Technologien ein, um die allgemeine Sicherheit unserer Website zu gewährleisten.    </div>

    <div class="block">
      <b>Ihre Rechte</b><br>
      Da wir keine persönlichen Daten erfassen, sind Anfragen zur Datenbearbeitung, wie Zugriff, Berichtigung oder Löschung, nicht anwendbar. Sollten Sie dennoch Fragen oder Bedenken bezüglich Ihrer Daten haben, können Sie uns gerne kontaktieren.    </div>


    <div class="block">
      <b>Änderungen an der Datenschutzerklärung</b><br>
      Wir behalten uns das Recht vor, diese Datenschutzerklärung bei Bedarf zu ändern oder zu aktualisieren. Alle Änderungen werden auf dieser Seite veröffentlicht.
    </div>

    <div class="block">
      <b>Kontaktinformationen</b><br>
      Bei Fragen zur Datenschutzerklärung oder zur Sicherheit auf unserer Website kontaktieren Sie uns bitte über [Ihre Kontakt-E-Mail-Adresse oder Kontaktformular-Link].    </div>
  </section>
</template>
